@import "defaults.scss";

body {
	color: $defaultFontColor;
}

.paper {
	max-width: 936px;
	margin: auto;
	overflow: hidden;

	table {
		thead {
			background-color: #f5f5f5;
			tr {
				th {
					font-size: $defaultFontSize;
					padding-top: $defaultSpacing;
					padding-bottom: $defaultSpacing;
					padding-left: $defaultSpacing * 2;
					padding-right: $defaultSpacing * 2;

					&:first-child {
						padding-left: $defaultSpacing * 4;
					}

					&:last-child {
						padding-right: $defaultSpacing * 4;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					padding-top: $defaultSpacing * 2;
					padding-bottom: $defaultSpacing * 2;
					padding-left: $defaultSpacing * 2;
					padding-right: $defaultSpacing * 2;

					&:first-child {
						padding-left: $defaultSpacing * 4;
					}

					&:last-child {
						padding-right: $defaultSpacing * 4;
					}
				}
			}
		}
	}
}

.search-bar {
	&.no-results {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}

.search-input {
	font-size: $defaultFontSize;
}

.block {
	display: "block";
}

.add-user {
	margin-right: $defaultSpacing * 2;
}

.content-wrapper {
	margin: 40px 16px;
}

Button {
	&:not(:only-child) {
		&:not(:last-child) {
			margin-right: $defaultSpacing;
		}
	}
}

a {
	text-decoration: inherit;
}

table {
	width: 100%;

	thead {
		tr {
			th {
				text-align: left;

				&:last-of-type {
					text-align: right;
				}

				&.left {
					text-align: left;
				}
			}
		}
	}

	td {
		&:last-of-type {
			text-align: right;
		}

		&.left {
			text-align: left;
		}
	}
}

.action-bar {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);

	.action-input {
		input {
			min-width: 9em;
			text-align: right;
		}
	}
}

.MuiTable-root {
	table-layout: fixed;
}

.table-cell-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.data-wrapper {
	&.uuid {
		text-overflow: ellipsis;
		max-width: 6em;
	}
	&.iban {
		text-overflow: ellipsis;
		max-width: 6em;
	}
}

.clickable {
	cursor: pointer;
}

label {
	color: rgba(0, 0, 0, 0.54);
	display: block;
	font-size: 13px;
	padding-bottom: 3px;
}

p {
	margin-top: 2px;
}

.document-image {
	width: calc(100% - 20px);
	margin: 0 10px 10px 10px;
}

.tab-panel {
	img {
		max-width: 100%;
	}
}

/** SM **/
@media only screen and (min-width: 600px) {
}

/** MD **/
@media only screen and (min-width: 960px) {
	.card-menu-container {
		max-width: 216px;
	}

	.card-details-container {
	}

	.card-content-wrapper {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}
}

/** LG **/
@media only screen and (min-width: 1280px) {
	.card-menu-container {
		max-width: 216px;
	}
}

/** XL **/
@media only screen and (min-width: 1920px) {
}

/** XS everything else **/
